@import '~antd/dist/antd.css';

body {
    /*font-family: 'Open Sans', sans-serif;*/
}


.btn_1 {
    border-radius: 1000px;
    padding: 13px;
}

.ant-modal-header {
    border-radius: 20px;
}
.ant-modal-content {
    border-radius: 20px;
}

.ant-modal-body {
    padding: 10px;
}

.ant-input:focus, .ant-input-focused {
    box-shadow: inherit !important;
}

.ant-modal-close-x {
    font-size: 36px;
}

.ant-modal-close-x {
    border: none !important;
}

.ant-menu-item{
    display: flex;
    flex-direction: revert;
    align-items: center;
}

.ant-drawer-close {
    font-size: 30px;
}

.ant-page-header-back-button {
    font-size: 37px;
}

/**
  Page titles, restaurant name
 */
span.ant-page-header-heading-title {
    font-size: 25px;
    font-weight: bold;
}
.ant-page-header {
    padding: 16px 0;
}

